<style>

</style>

<template>
    <div id="payment-failure-report"  style="padding: 0 1.5rem">
        <div class="pb-1 mb-0 mb-lg-1">
            <SearchForm @search="search" :reportName="reportName" :isProcessing="isProcessing"></SearchForm>
            <ReportTable
                :paymentFailures="paymentFailures"
                :totalRows="totalRows"
                :reportName="reportName"
                :isProcessing="isProcessing"
                @search="search"
                @get-details="getDetails"
            ></ReportTable>
        </div>
        <div
        id="payment_failure_detail_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="payment_failure_detail_modal"
        class="modal fade"
        aria-modal="true"
        style="display: none"
        >
        <div role="document" class="modal-dialog modal-lg modal2detail4pk">
            <div class="modal-content">
            <div class="modal-header">
                <h4 id="payment_failure_detail_modal_label" class="modal-title">
                {{$t("detailHeader")}}
                </h4>
                <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <dl class="row mt-2 py-2 bg-white rounded shadow px-2">  
                        <dt class="col-sm-12"><h6>{{$t("custInfo")}}</h6></dt>
                        <dd></dd>
                        <div class="col-7">
                            <div class="row">
                            <dt class="col-sm-6">{{$t("custName")}}</dt>
                            <dd class="col-sm-6">{{showNullWithDash(paymentFailure?.cust_name)}}</dd>
                            </div>
                            <div class="row">
                            <dt class="col-sm-6">{{$t("custMobile")}}</dt>
                            <dd class="col-sm-6">{{showNullWithDash(paymentFailure?.cust_mobile)}}</dd>
                            </div>
                            <div class="row">
                            <dt class="col-sm-6">{{$t("custEmail")}}</dt>
                            <dd class="col-sm-6">{{showNullWithDash(paymentFailure?.cust_email)}}</dd>
                            </div>
                        </div>
                        <div class="col-5">
                        </div>
                    </dl>
                    <dl class="row mt-2 py-2 bg-white rounded shadow px-2" v-if="!isNullOrEmpty(paymentFailure?.paymentInfoDetails)">
                        <dt class="col-sm-12"><h6>{{$t("paymentInformation")}}</h6></dt>
                        <dd></dd>
                        <div class="col-12 table-responsive">
                        <table class="table table-flush dataTable-table z1 table-hover">
                            <thead class="text-primary">
                            <tr>
                                <th scope="col">{{$t('paymentMethod')}}</th>
                                <th scope="col">{{$t('paymentFrequency')}}</th>
                                <th scope="col">{{$t('installmentNumber')}}</th>
                                <th scope="col">{{$t('dueDate')}}</th>
                                <th scope="col">{{$t('paymentDate')}}</th>
                                <th scope="col">{{$t('paymentStatus')}}</th>
                                <th scope="col">{{$t('netAmount')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(paymentInfoDetail, index) in paymentFailure.paymentInfoDetails" :key="'paymentInfoDetail' + index">
                                <td>{{paymentInfoDetail.payment_method__c}}</td>
                                <td>{{displayPaymentPlan(paymentInfoDetail.payment_frequency__c)}}</td>
                                <td>{{paymentInfoDetail.installment_number__c}}</td>
                                <td>{{paymentInfoDetail.due_date__c}}</td>
                                <td>{{showNullWithDash(paymentInfoDetail.payment_date__c)}}</td>
                                <td>{{paymentInfoDetail.status__c}}</td>
                                <td>{{paymentInfoDetail.installmentamount}}</td>
                                <!-- <td>
                                {{premiumDetail.transaction_type__c}}
                                <p class="mb-0" v-if="premiumDetail.transaction_type__c === 'Endorsement'"><small>{{premiumDetail.endorsement_reasons_name}}</small></p>
                                <p class="mb-0" v-if="premiumDetail.transaction_type__c === 'Cancellation'"><small>{{premiumDetail.reason_for_cancellation__c}}</small></p>
                                </td>
                                <td>{{formattPrice(premiumDetail.trans_net_premium__c,2)}}</td>
                                <td>{{formattPrice(premiumDetail.policy_gross_premium__c,2)}}</td> -->
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </dl>
                    <dl class="row mt-2 py-2 bg-white rounded shadow px-2">
                        <dt class="col-sm-12"><h6>{{$t("link")}}</h6></dt>
                        <dd></dd>
                        <div class="col-12">
                            <PayOnlineLink v-if="isShowPayOnlineLink" :oppname="paymentFailure.oppname" :link="paymentFailure.paymentURL" :isRecurring="false"></PayOnlineLink>
                            <PayOnlineLink v-else-if="isShowRecurringLink" :oppname="paymentFailure.oppname" :link="paymentFailure.paymentURLRecurring" :isRecurring="true"></PayOnlineLink>
                        </div>
                    </dl>
                </div>
            </div>

            <div class="modal-footer">
                <button
                type="button"
                class="btn btn-primary text-white bg-gradient-primary"
                data-bs-dismiss="modal"
                >
                {{$t('menu.close')}}
                </button>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import Global from "@/stores/GlobalVariables.js";
import mixin from "@/components/Mixin";
import SearchForm from "@/components/SearchForm";
import ReportTable from "@/components/ReportTable";
import PayOnlineLink from "@/components/PayOnlineLink";
import axios from "axios";

export default {
    mixins: [mixin],
    name: "PaymentFailureReport",
    components: { SearchForm, ReportTable,PayOnlineLink },
    data: function() {
        return {
            Global,
            paymentFailures: [],
            totalRows: 0,
            paymentFailure: null,
            isProcessing: false,
            // isExportProcessing: true,
            // exportUuid: null,
            reportName: "paymentFailureReport",
            shortLinkIntervalIds: []
        }
    },
    created() {
        if(!this.isNullOrEmpty(this.$route.query.start) && !this.isNullOrEmpty(this.$route.query.end) && !this.isNullOrEmpty(this.$route.query.dateType) ){
            Global.searchConditions[this.reportName].dateFrom = this.$route.query.start;
            Global.searchConditions[this.reportName].dateTo = this.$route.query.end;
            Global.searchConditions[this.reportName].dateType = this.$route.query.dateType;
            Global.searchConditions[this.reportName].status = this.$route.query.status;
            Global.searchConditions[this.reportName].paymentStatus = this.$route.query.paymentStatus;
            Global.searchConditions[this.reportName].oppNumber = "";
            Global.searchConditions[this.reportName].custName = "";
            Global.searchConditions[this.reportName].phoneNumber = "";
            Global.searchConditions[this.reportName].custEmail = "";
            Global.searchConditions[this.reportName].carPlate = "";
            if (this.$route.query.paymentStatus === 'overdue') {
                Global.searchConditions[this.reportName].sortKey = 'duedate';
                Global.searchConditions[this.reportName].sortDirection = 'asc';
            }
        }else{
            Global.searchConditions[this.reportName].dateFrom = "";
            Global.searchConditions[this.reportName].dateTo = "";
            Global.searchConditions[this.reportName].productType = "";
            Global.searchConditions[this.reportName].status = "Activated";
            Global.searchConditions[this.reportName].paymentStatus = "";
            Global.searchConditions[this.reportName].dateType = "duedate";
            Global.searchConditions[this.reportName].sortKey = "duedate";
            Global.searchConditions[this.reportName].sortDirection = "desc";
            Global.searchConditions[this.reportName].oppNumber = "";
            Global.searchConditions[this.reportName].custName = "";
            Global.searchConditions[this.reportName].phoneNumber = "";
            Global.searchConditions[this.reportName].custEmail = "";
            Global.searchConditions[this.reportName].carPlate = "";
            Global.searchConditions[this.reportName].agentEmail = "";
            Global.searchConditions[this.reportName].currentPage = 1;
            Global.searchConditions[this.reportName].rowsPerPage = 20;
            Global.searchConditions[this.reportName].isAdvanceSearch = false;
        }
        this.eventTracking('mainMenu', 'Menu_PaymentReport', 'Portal');
    },
    mounted() {
        var vm = this;
        this.hide2menu();
        this.search()
        var myModalEl = document.getElementById('payment_failure_detail_modal')
        if(myModalEl){
            myModalEl.addEventListener('hide.bs.modal', function () {
                vm.paymentFailure = null;
            })
        }
    },
    methods: {
        async search() {
            this.isProcessing = true;
            // this.isExportProcessing = true;
            try {
                var response = await axios.get(
                Global.apiUrl + "/paymentFailureReport",
                {
                    params: Global.searchConditions[this.reportName],
                }
                );
                this.paymentFailures = response.data.paymentFailures;
                this.totalRows = parseInt(response.data.count);
                // Global.searchConditions[this.reportName].exportUuid = response.data.exportUuid;
                this.isProcessing = false;
                // this.checkFinishGenerateShortLink(Global.searchConditions[this.reportName].exportUuid)
            } catch (error) {
                if (error?.response?.status === 401) {
                this.isProcessing = false;
                this.$router.go();
                }
                console.error(error);
            }
        },
        async getDetails(paymentFailure){
            this.topLoading("show");
            this.paymentFailure = Object.assign({}, paymentFailure);
            try {
                // var response = await axios.get(Global.apiUrl + "/paymentFailureReport/details/" + paymentFailure.oppname);
                var response = await axios.get(Global.apiUrl + "/paymentFailureReport/details/" + paymentFailure.oppname + "/" + paymentFailure.product_type__c + "/" + paymentFailure.transaction_type__c + "/" + paymentFailure.online_stage + "/" + paymentFailure.stagename);
                if(response){
                    var details = response.data.details;
                    this.paymentFailure.premium = details.premium;
                    this.paymentFailure.paymentInfoDetails = response.data.paymentInfoDetails;
                    this.paymentFailure.paymentURL = response.data.payOnlineURL;
                    this.paymentFailure.paymentURLRecurring = response.data.payOnlineURLRecurring;
                    if(['Car', 'CarEV', 'MotorBike'].indexOf(this.paymentFailure.product_type__c) > -1){
                        this.paymentFailure.excess__c = details.excess__c;
                        this.paymentFailure.workshop_type__c = details.workshop_type__c;
                        this.paymentFailure.driver_plan__c = details.driver_plan__c;
                        this.paymentFailure.compulsory_plan__c = details.compulsory_plan__c;
                        this.paymentFailure.make__c = details.make__c;
                        this.paymentFailure.model_family__c = details.model_family__c;
                        this.paymentFailure.year_of_manufacture__c = details.year_of_manufacture__c;
                        this.paymentFailure.car_plate_number__c = details.car_plate_number__c;
                    }else{
                        this.paymentFailure.option_list = details.option_list;
                        this.paymentFailure.approval_status__c = details.approval_status__c;
                    }
                }
                this.topLoading("hide");
                var modal = this.modal("#payment_failure_detail_modal");
                if( modal ){
                modal.show();
                }
            } catch (error) {
                if (error?.response?.status === 401) {
                this.topLoading("hide");
                this.$router.go();
                }
                console.error(error);
            }
        }
    },
    computed: {
    isShowPayOnlineLink: function(){
      return this.paymentFailure?.transaction_type__c === 'New Biz' 
              && this.paymentFailure?.online_stage === 'Proceed to payment' 
              && this.paymentFailure?.stagename === 'Proposal'
              && this.paymentFailure?.product_type != 'Extended Warranty';
    },
    isShowRecurringLink: function(){
      return ['Ground agent', 'Traditional broker'].includes(Global.brokerDetails.accountSource) 
              && this.paymentFailure?.stagename === 'Closed Won' 
              && this.paymentFailure?.paymentURLRecurring;
    }
  }
}
</script>

<i18n>
{
  "en": {
    "detailHeader": "Payment Detail",
    "oppNumber": "paymentFailure Number",
    "productOption": "Product Option",
    "excess": "Excess",
    "garage": "Garage",
    "driver": "Driver",
    "compulsory": "Compulsory",
    "createdDate": "Created Date",
    "startDate": "Start date",
    "activateDate":	"Activated Date",
    "stage": "Stage",
    "status": "Status",
    "brokerName": "Broker Name",
    "agentEmail": "Agent email",
    "product": "Product",
    "brand": "Brand",
    "model": "Model",
    "subModel": "Sub Model",
    "year": "Years of manufacture",
    "carPlate": "Car Plate",
    "dueDate": "Due Date",
    "amount": "Amount",
    "custInfo": "Customer Information",
    "custName": "Name",
    "custMobile": "Mobile Number",
    "custEmail": "Email",
    "businessValidate": "Business validate",
    "notPass": "Not Pass",
    "premium": "Premium",
    "activatedDate": "Activated date",
    "fullPayment": "Full",
    "12monthsInstallment": "12 months installment",
    "10monthsInstallment": "10 months installment with deposit",
    "10monthsInstallmentNoDeposit": "10 months installment",
    "4monthsInstallment": "4 months installment",
    "paymentPlan": "Payment Plan",
    "overdueInstallment": "Overdue installment",
    "installmentNumber": "Installment number",
    "link": "Link",
    "paymentInformation":"Payment Information",
    "paymentMethod": "Payment Method",
    "paymentFrequency": "Payment",
    "installmentNumber": "Installment number",
    "dueDate": "Due Date",
    "paymentDate": "Payment Date",
    "paymentStatus": "Status",
    "netAmount": "Net Amount"
  },
  "th": {
    "detailHeader": "รายละเอียดการชำระเงิน",
    "oppNumber": "หมายเลขใบเสนอราคา",
    "productOption": "ตัวเลือกผลิตภัณฑ์",
    "excess": "ค่าเสียหายส่วนแรก",
    "garage": "อู่",
    "driver": "ผู้ขับขี่",
    "compulsory": "พ.ร.บ",
    "createdDate": "วันที่สร้าง",
    "startDate": "วันเริ่มกรมธรรม์",
    "activateDate":	"วันที่ปิดการขาย",
    "stage": "สถานะใบเสนอ",
    "status": "สถานะ",
    "brokerName": "โบรกเกอร์",
    "agentEmail": "อีเมล์ที่ปรึกษา",
    "product": "ผลิตภัณฑ์",
    "brand": "ยี่ห้อ",
    "model": "รุ่น",
    "subModel": "รายละเอียดรุ่น",
    "year": "ปีที่ผลิต",
    "carPlate": "ป้ายทะเบียน",
    "dueDate": "วันครบกำหนด",
    "amount": "จำนวน",
    "custInfo": "รายละเอียดข้อมูลลูกค้า",
    "custName": "ชื่อลูกค้า",
    "custMobile": "เบอร์โทรศัพท์",
    "custEmail": "อีเมล์",
    "businessValidate": "การรับประกัน",
    "notPass": "ไม่ผ่าน",
    "premium": "เบี้ยประกันภัย",
    "activatedDate": "วันที่ปิดการขาย",
    "fullPayment": "จ่ายเต็มจำนวน",
    "12monthsInstallment": "ผ่อน 12 งวด",
    "10monthsInstallment": "ผ่อน 10 งวด (มีมัดจำ)",
    "10monthsInstallmentNoDeposit": "ผ่อน 10 งวด",
    "4monthsInstallment": "ผ่อน 4 งวด",
    "paymentPlan": "แผนการชำระเงิน",
    "overdueInstallment": "ยอดที่ค้างชำระ",
    "installmentNumber": "งวดที่",
    "link": "ลิงก์",
    "paymentInformation":"รายละเอียดการชำระเงิน",
    "paymentMethod": "วิธีการชำระเงิน",
    "paymentFrequency": "การชำระเงิน",
    "installmentNumber": "Installment number",
    "dueDate": "วันครบกำหนด",
    "paymentDate": "Payment Date",
    "paymentStatus": "Status",
    "netAmount": "Net Amount"
  },
  "zh": {
    "detailHeader": "Payment Detail",
    "oppNumber": "机会号码",
    "productOption": "Product Option",
    "excess": "超额部分",
    "garage": "维修店铺",
    "driver": "司机",
    "compulsory": "强制性的",
    "createdDate": "创建日期",
    "startDate": "开始日期",
    "activateDate":	"Activated Date",
    "stage": "Stage",
    "status": "状态",
    "brokerName": "经纪人姓名",
    "agentEmail": "代理邮箱",
    "product": "产品",
    "brand": "品牌",
    "model": "型号",
    "subModel": "子型号",
    "year": "生产年份",
    "carPlate": "车牌",
    "dueDate": "Due Date",
    "amount": "Amount",
    "custInfo": "客户资料",
    "custName": "名称",
    "custMobile": "客户手机号码",
    "custEmail": "客户邮箱",
    "businessValidate": "商业验证",
    "notPass": "不通过",
    "premium": "保费",
    "activatedDate": "Activated date",
    "fullPayment": "Full",
    "12monthsInstallment": "12 months installment",
    "10monthsInstallment": "10 months installment with deposit",
    "10monthsInstallmentNoDeposit": "10 months installment",
    "4monthsInstallment": "4 months installment",
    "paymentPlan": "Payment Plan",
    "overdueInstallment": "Overdue installment",
    "installmentNumber": "Installment number",
    "link": "链接",
    "paymentInformation":"Payment Information",
    "paymentMethod": "Payment Method",
    "paymentFrequency": "Payment",
    "installmentNumber": "Installment number",
    "dueDate": "Due Date",
    "paymentDate": "Payment Date",
    "paymentStatus": "Status",
    "netAmount": "Net Amount"
  }
}
</i18n>
